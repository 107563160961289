// Usage: This file is used to add products/items to your website.

/**
 * The products/items that you want to showcase on your website.
 * @type {Array}
 * @property {string} id - The unique identifier for the product. (required)
 * @property {string} name - The name of the product. (optional but recommended)
 * @property {string} image - The URL of the image for the product. (optional but recommended)
 * @property {string} description - A brief description of the product. (optional but recommended)
 * @property {string} category - The category of the product. (optional but recommended)
 * @property {string} link - The URL to the product page. (optional)
 */
const products = [
    {
        id: "1",
        name: "Aesthoplum",
        image: "https://tonhiwqrovgizwfeagfl.supabase.co/storage/v1/object/public/Aesthoplum%20Images/veiled-moments.jpg",
        description: "A website for a photographer showcasing their portfolio with contact information.",
        category: "Photography Catalog",
        link: "https://aesthoplum.vercel.app",
    },
    {
        id: "2",
        name: "Vinyl Garage Ltd.",
        image: "https://img.freepik.com/free-photo/closeup-shot-round-headlights-white-vintage-classic-car_181624-21161.jpg?t=st=1730009412~exp",
        description: "A website for a garage which lists the various car products that it offers.",
        category: "E-Commerce",
        link: "https://vinylgarageltd.vercel.app",
    },
];






















// Do not modify below this line

function getProductById(id) {
    return products.find(product => product.id === id);
}

function getCategories() {
    const categories = products.map(product => product.category);
    const uniqueCategories = [...new Set(categories)];
    return uniqueCategories.map(category => ({ categoryName: category }));
}

function getSimilarProducts(id) {
    const { category } = products.find(product => product.id === id);
    return products.filter(product => product.category === category && product.id !== id);
}

export default products;
export { getProductById, getCategories, getSimilarProducts };
